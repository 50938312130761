
body{
  font-family: 'Kalam';
  scroll-behavior: smooth;
}

h3{
  font-weight: 700;
}
h1{
  padding-top:70px;
  padding-bottom:10px;
  font-weight: 700;
}
h2{
  font-weight: 700;
}

h6{
  font-size: 17px;
}

h5{
  padding-top:5px;
}

.image {
  height: auto;
  width: 100%;
  margin: 0 auto;
}

.choose:hover{
  filter: brightness(100%)
}

.color{
  color: rgb(211, 233, 235);
  background-color: rgb(235, 233, 211);
}

.profile{
  border-radius: 250px;
  height: auto;
  width: 100%;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
}
.profile:hover{
  filter: brightness(110%)
}

.skills{
  position: relative;
  width: 100%;
  padding-top: 100%;
}

input{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

textarea{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.input-custom{
  margin-bottom: 20px;
  height:50px;
  font-size: 18px;
  border-radius: 25px;
  padding-left: 16px;
  padding-right: 16px;
}
.circle{
  position: absolute;
  height:20%;
  width: 20%;
  border-radius: 50%;
  color:white;
  background-color: orange;
  font-size: 20px;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  &:hover {
    width: 23%;
    height: 23%;
    font-size: 23px;
  }
  div{
    padding-top: 35%;
  }
}
.circle-small{
  width: auto;
  min-width: 30px;
  padding: 0 8px 1px 8px;
  background-color: rgba(255, 171, 44, 0.8);
  border-radius: 30px;
  text-align: center;
  color: white;
  font-style: normal;
  font-weight: 500;
  margin: 5px 2px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.slide-pic{
  height: 30vh;
  min-height: 14vw;
  overflow: hidden
}

p{
  padding-top:25px;
  font-size:20px
}

.pointer{
  cursor:pointer;
  height:35px;
  width:35px;
  margin:5px;
  margin-left:10px;
}
.brand-icon{
  color: white;
}
a{
  text-decoration: none;
}
a:link, a:visited{
  color: rgb(214, 214, 214);
  text-decoration: none;
}
.brand-icon:hover{
  color: rgb(214, 214, 214);
  cursor: pointer;
}

.navlink{
  cursor: pointer;
  margin-left:15px;
  padding-top:10px;
  font-weight: 700;
  font-size: 22px;
}

.navlink-active{
  color: rgb(255, 118, 118);
}

.navback{
  background-color: rgba(255,255,255,0.3);
  padding: 5px;
  position: fixed;
  width:100vw;
  z-index: 10;
}

footer{
  background-color: rgb(148, 130, 102);
  min-height:12vh;
  color:white;
  padding: 1vw 0;
}

label {
  color:rgb(156, 156, 156);
  position: absolute;
  left: 0%;
  transform: translate(0, 16px) scale(1);
  transition: all .1s ease-in-out;
  padding-left: 14px;
  pointer-events:none;
}

.uppercard{
  margin-top:5px;
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.lowercard{
  width: 100%;
  background-color: rgba(255,255,255,0.5);
  padding: 15px 20px 20px 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.shadow{
  box-shadow: 0px 14px 10px rgba(0, 0, 0, 0.4);
}

button {
  background-color: #FF8178; 
  border: none;
  border-radius: 23px;
  color: white;
  padding: 7px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.2);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  &:hover{
    background-color: rgb(255, 255, 255); 
    color: black;
  }
  &:focus {outline:0;}
}

.block-left{
  margin: 0 auto;
  min-width: 40%;
}

.block-right{
  width: 50%;
  padding-top: 70px;
}
.chooser{
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  height: 350px;
  position: relative;
}
.choose{
  height:auto;
  cursor: pointer;
  background-color: white;
}
.choose-passive{
  margin-top: 10%;
  box-shadow: 0px 1px 33px rgba(0, 0, 0, 0.39);
  filter: brightness(80%);
  width: 75%;
}
.choose-active{
  box-shadow: 0px 1px 33px rgb(255, 174, 0);
  width: 90%;
}
.arrow-button{
  margin-top: 30px;
  color: rgb(117, 117, 117);
  font-size: 55px;
  cursor: pointer;
}
.arrow-button:hover{
  color: rgb(173, 173, 173);
}
.container-component{
  display: flex;
  flex-wrap: wrap;
}
.green{
  background-color: #7EBF83;
}
.icon {
  position: fixed;
  width: 50%;
  left: 0%;
  bottom: 10%;
  text-align: center;
}
.pic{
  max-width: 600px;
  max-height: 80vh;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
.container-skills{
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
}
.block-right-inside{
  max-width: 600px;
  margin-right: auto;
}
.spacer-bottom{
  height: 0px;
}
.project-img{
  width: 80%;
  height: auto;
  max-width: 270px;
}

.carousel-control-prev-icon
{
  font-family: 'Courier New', Courier, monospace;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px 15px;
}

.carousel-control-next-icon
{
  font-family: 'Courier New', Courier, monospace;
  color: rgba(255,255,255,0.5);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px 15px;
}

.link {
  color: rgb(102, 102, 102);
  &:hover {
    color: rgb(0, 0, 0)
  }
}

.blog {
  color: black;
  text-decoration: none;
  background-color: rgba(255,255,255,0.5);
  &:hover{
    box-shadow: 0px 0px 30px rgba(255, 171, 44, 0.8);
    cursor: pointer;
    background-color: rgba(255,255,255,0.8);
  }
}

@media screen and (max-width: 1200px) {
  .block-left{
    min-width: 100%;
  }
  .block-right{
    margin: auto;
    padding-top: 10px;
    width: 100%;
  }
  .icon{
    width: 100%;
  }
  .chooser{
    margin-top: 50px;
  }
  .container-skills{
    max-width: 550px;
  }
  .circle{
    font-size: 19px;
  }
  .block-right-inside{
    margin: auto;
    padding: 20px;
  }
  .spacer-bottom{
    height: 300px
  }
  .circle:hover{
    font-size: 21px;
  }
}

@media screen and (max-width: 800px) {
  h1{
    font-size: 31px;
  }
  .container-skills{
    max-width: 400px;
  }
  .circle{
    font-size: 13px;
    &:hover{
      font-size: 15px;
    }
  }
  h3{
    font-size: 20px;
  }
  h4{
    font-size: 15px;
  }
  h6{
    font-size: 15px;
  }
  button{
    font-size: 18px;
  }
  .block-right-inside{
    padding: 10px
  }
  .slide-pic{
    height: 25vh;
  }
  .navlink{
    padding-top: 12px;
    font-size: 19px;
  }
  .chooser{
    height: 300px;
  }
}

@media screen and (max-width: 400px) {
  h5{
    font-size: 15px;
  }
  .navlink{
    padding-top: 12px;
    font-size: 15px;
  }
  .block-right-inside{
    padding: 5px
  }
  .chooser{
    height: 230px
  }
  .arrow-button{
    font-size: 45px;
  }
}
.stage {
  display: flex;
}
.box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  font-weight: 700;
  font-style: normal;
  color: rgb(61, 61, 61)
}
.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}
@keyframes bounce-7 {
  0%   { transform:     translateY(0); }
  10%  { transform:    translateY(0); }
  30%  { transform:    translateY(-15%); }
  50%  { transform:  translateY(0); }
  57%  { transform:      translateY(-5%); }
  64%  { transform:       translateY(0); }
  100% { transform:      translateY(0); }
}